body {
 background-color:black; 
 color: aliceblue;
 
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 background-attachment: fixed;
 padding: 0;
 margin: 0;
}
