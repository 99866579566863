@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");
@keyframes fadeInOutOpacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeShow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
div {
  animation: 1s ease-out 0s 1 fadeInOpacity;
}
.btn-369 {
  color: white;
  display: inline-flex;
  padding: 8px;
  border-radius: 8px;
}
.btn-menu {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid white;
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
.btn-menu:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}
.text-green {
  color: #39ff14 !important;
}
.max-h-56 {
  max-height: 56px !important ;
}
.h-750 {
  height: 750px !important;
}
.max-h-100 {
  max-height: 100px !important ;
}
.h-175 {
  max-height: 175px !important;
}
.h-600 {
  height: 600px !important;
}
.navbar-item-custom:hover {
  color: #39ff14 !important;
  transform: scale(1.2);
}
.offcanvas.offcanvas-start {
  width: 200px !important;
}
.fixed-bottom {
  z-index: 1000 !important;
}
.work {
  padding-top: 66px;
  padding-bottom: 56px;
  padding-left: 12px;
  padding-right: 12px;
}
.op-8 {
  opacity: 0.8;
}
.req-form {
  max-height: 25px !important ;
}
.max-h-work {
  max-height: calc(100vh - 122px);
}
.min-h-work {
  min-height: calc(100vh - 122px);
}
.min-h-30 {
  min-height: 30px !important;
}
.h-400n {
  height: 400px !important;
}
.h-400 {
  min-height: 400px !important;
}
.h-300n {
  height: 300px !important;
}
.h-300 {
  min-height: 300px !important;
}
.div-work {
  border-radius: 15px;
  padding: 2rem;
  background: -webkit-linear-gradient(360deg, #1c1c1c 10%, #494949 360%);
  background: -moz-linear-gradient(360deg, #1c1c1c 10%, #494949 360%);
  background: linear-gradient(360deg, #1c1c1c 10%, #494949 360%);
  box-shadow: 0px 0px 1.7px rgba(83, 83, 83, 0.141),
    0px 0px 4.6px rgba(83, 83, 83, 0.196),
    0px 0px 11.2px rgba(83, 83, 83, 0.258), 0px 0px 37px rgba(83, 83, 83, 0.44);
  border: 1px solid #5a5959;
  transition: all 1s;
}
.div-work:hover {
  transform: scale(1.02);

  transition: all 1s;
}
.shine {
  background-repeat: no-repeat;
  box-shadow: 0px 0px 1.7px rgba(83, 83, 83, 0.141),
    0px 0px 4.6px rgba(83, 83, 83, 0.196),
    0px 0px 11.2px rgba(83, 83, 83, 0.258), 0px 0px 37px rgba(83, 83, 83, 0.44);
  top: 0px;

  overflow: hidden;
  display: inline-block;
}

.shine:after {
  content: "";
  position: absolute;
  top: -50%;
  left: -60%;
  width: 20%;
  height: 200%;
  opacity: 0;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* Hover state - trigger effect */
.shine:hover:after {
  opacity: 1;
  left: 130%;

  transition-property: left, top, opacity;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-timing-function: ease;
}

/* Active state */
.shine:active:after {
  opacity: 0;
}
.BR-5 {
  border-radius: 9px;
}
.btn-s {
  min-width: 100px;
  max-width: 200px;
  color: #fff;
  padding: 7px 7px;
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.23) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 66% at 26% 20%,
      rgba(255, 255, 255, 0.55) 0%,
      rgba(255, 255, 255, 0) 69.79%,
      rgba(255, 255, 255, 0) 100%
    );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25),
    inset 0px 3px 9px rgba(255, 255, 255, 0.3),
    inset 0px 1px 1px rgba(255, 255, 255, 0.6),
    inset 0px -8px 36px rgba(0, 0, 0, 0.3),
    inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  font-size: 12px;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  transition: all 1s;
}
.btn-s:hover {
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.329) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 66% at 26% 20%,
      rgba(255, 255, 255, 0.63) 0%,
      rgba(255, 255, 255, 0.096) 69.79%,
      rgba(255, 255, 255, 0.027) 100%
    );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.493),
    inset 0px 3px 9px rgba(255, 255, 255, 0.548),
    inset 0px 1px 1px rgba(255, 255, 255, 0.801),
    inset 0px -8px 36px rgba(0, 0, 0, 0.39),
    inset 0px 1px 5px rgba(255, 255, 255, 0.671),
    2px 19px 31px rgba(0, 0, 0, 0.2);
  transition: all 1s;
}
.btn-s:active {
  background-image: radial-gradient(
      93% 87% at 87% 89%,
      rgba(0, 0, 0, 0.555) 0%,
      transparent 86.18%
    ),
    radial-gradient(
      66% 66% at 26% 20%,
      rgba(255, 255, 255, 0.74) 0%,
      rgba(255, 255, 255, 0.301) 69.79%,
      rgba(255, 255, 255, 0.137) 100%
    );
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.589),
    inset 0px 3px 9px rgba(255, 255, 255, 0.678),
    inset 0px 1px 1px rgba(255, 255, 255, 0.801),
    inset 0px -8px 36px rgba(0, 0, 0, 0.445),
    inset 0px 1px 5px rgba(255, 255, 255, 0.733),
    2px 19px 31px rgba(0, 0, 0, 0.2);
  transition: all 1s;
}
.btn-disable {
  color: #aca1a1;
  background-color: #4d4747 !important;
  background-image: none !important;
  box-shadow: none !important;
  cursor: context-menu !important;
}

.bg-green {
  background-color: #24ac0c;
}
.bg-red {
  background-color: #ff0000;
}
.w-300 {
  width: 300px;
}
.w-80 {
  width: 80% !important;
}
.max-w-500 {
  max-width: 500px !important;
}
.max-w-700 {
  max-width: 700px !important;
}
.max-w-992 {
  max-width: 992px !important;
}
.max-w-200 {
  max-width: 200px !important;
}
.h-100 {
  height: 100% !important;
}
.profile-pic {
  border: 2px solid #37ff1454;
  border-radius: 50%;
  box-shadow: rgba(52, 197, 52, 0.452) 0px 2px 20px;
  transition: all 1s;
}
.profile-pic:hover {
  margin-top: 10px;
  transform: scale(1.5);
  transition: all 1s;
}
.fs-7 {
  font-size: 0.85rem !important ;
}
.fs-8 {
  font-size: 0.65rem !important ;
}
.L-card {
  box-shadow: rgba(131, 129, 129, 0.35) 0px 0px 9px;
  transition: all 1s;
}
.L-card:hover {
  box-shadow: rgba(131, 129, 129, 0.582) 0px 0px 9px;

  transition: all 1s;
}
.L-rank {
  box-shadow: rgba(131, 129, 129, 0.35) 0px 0px 9px;
  transition: all 1s;
}
.c-pointer {
  cursor: pointer !important;
}
.c-reg {
  cursor: context-menu !important;
}
.not-underline {
  text-decoration: none;
}
.target {
  z-index: 1000 !important;

  box-shadow: rgba(45, 231, 70, 0.76) 0px 0px 15px;
  transform: scale(1.1);
  transition: all 1.5s;
}
.target-done {
  z-index: 1000 !important;
  box-shadow: rgba(131, 129, 129, 0.35) 0px 0px 9px;
  transform: scale(1);
  transition: all 1.5s;
}
.w-fit {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.btn-scale {
  transition: all 0.5s !important;
}
.btn-scale:hover {
  transform: scale(1.09) !important;
  transition: all 0.5s;
  color: #39ff14 !important;
}
.h-msg {
  min-height: calc(400px - 48px);
}
.msg-item {
  border-right: 1px dotted rgba(245, 222, 179, 0.616);
}
.border-white-05 {
  border-color: rgba(255, 255, 255, 0.473) !important;
}
.nav-active {
  color: #39ff14 !important;
  padding-left: 8px;
  animation: 1s ease-out 0s 1 fadeInOutOpacity;
  animation-iteration-count: infinite;
}
.p-l-8 {
  padding-left: 8px;
}
.disable-link {
  pointer-events: none;
}
.min-h-quest-menu {
  min-height: calc(100vh - 122px);
}
@media only screen and (max-width: 768px) {
  .min-h-quest-menu {
    min-height: 30px;
  }
}
.quests {
  height: calc(100vh - 122px);
  overflow-y: auto !important;
  border-left: 1px solid rgba(255, 255, 255, 0.253);
  /* border-top: 1px solid rgba(255, 255, 255, 0.253);
  border-bottom: 1px solid rgba(255, 255, 255, 0.253); */
}
@media only screen and (max-width: 768px) {
  .quests {
    height: calc(100vh - 122px - 50px);
    overflow-y: auto !important;
    border-left: 0px solid rgba(255, 255, 255, 0.253);
  }
}

.quest-item-daily {
  height: 230px !important;
  width: 330px !important;
  opacity: 0.8;
  /* box-shadow: 0 0 1rem 0 #50e481; */
  box-shadow: 0px 0px 8px hsla(49, 79%, 45%, 0.1);
  border: 3px solid #50e48193;
  background: linear-gradient(
    320deg,
    #022c1442 0%,
    #4ee45a54 30%,
    #022c1438 100%
  );
  background-position: center;
  background-size: 460px 660px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 9px;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}

.quest-item-daily-hover:hover {
  box-shadow: 0px 0px 15px hsla(49, 79%, 45%, 0.3);

  background-position: 40% 40% !important;

  border: 3px solid #50e481;
  transition: all 0.3s ease-in-out 0s;
}
.quest-daily-xp {
  border: 1px solid rgb(115, 129, 32);
  box-shadow: 0px 0px 8px rgba(114, 129, 32, 0.192);
  transition: all 1s;
}
.quest-item-weekly {
  height: 230px !important;
  width: 330px !important;
  opacity: 0.8;
  /* box-shadow: 0 0 1rem 0 #50e481; */
  box-shadow: 0px 0px 8px hsla(229, 79%, 45%, 0.1);
  border: 3px solid #31158093;
  background: linear-gradient(
    320deg,
    #02052c42 0%,
    #4e58e454 30%,
    #02052c42 100%
  );
  background-position: center;
  background-size: 460px 660px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 9px;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}

.quest-item-weekly-hover:hover {
  box-shadow: 0px 0px 15px hsla(229, 79%, 45%, 0.3);

  background-position: 40% 40% !important;

  border: 3px solid #321580fa;
  transition: all 0.3s ease-in-out 0s;
}
.quest-weekly-xp {
  border: 1px solid rgb(96, 30, 150);
  box-shadow: 0px 0px 8px rgba(112, 19, 89, 0.192);
  transition: all 1s;
}
.quest-item-notif {
  height: 230px !important;
  width: 330px !important;
  opacity: 0.8;
  /* box-shadow: 0 0 1rem 0 #50e481; */
  box-shadow: 0px 0px 8px hsla(323, 71%, 58%, 0.1);
  border: 3px solid #7c205693;
  background: linear-gradient(
    320deg,
    #29022c42 0%,
    #e44ebe54 30%,
    #29022c42 100%
  );
  background-position: center;
  background-size: 460px 660px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 9px;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}

.quest-item-notif-hover:hover {
  box-shadow: 0px 0px 15px hsla(323, 71%, 58%, 0.3);

  background-position: 40% 40% !important;

  border: 3px solid #7c205693;
  transition: all 0.3s ease-in-out 0s;
}
.quest-item-special {
  height: 230px !important;
  width: 330px !important;
  opacity: 0.8;
  /* box-shadow: 0 0 1rem 0 #50e481; */
  box-shadow: 0px 0px 8px hsla(44, 79%, 45%, 0.1);
  border: 3px solid #807e1593;
  background: linear-gradient(
    320deg,
    #2c220242 0%,
    #e4a34e54 30%,
    #2c220242 100%
  );
  background-position: center;
  background-size: 460px 660px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: 9px;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
}

.quest-item-special-hover:hover {
  box-shadow: 0px 0px 15px hsla(44, 79%, 45%, 0.3);

  background-position: 40% 40% !important;

  border: 3px solid #807e15;
  transition: all 0.3s ease-in-out 0s;
}
.quest-special-xp {
  border: 1px solid rgb(150, 112, 30);
  box-shadow: 0px 0px 8px rgba(112, 19, 89, 0.192);
  transition: all 1s;
}
.border-down {
  border-bottom: 1px solid rgba(255, 255, 255, 0.253);
}
.border-up {
  border-top: 1px solid rgba(255, 255, 255, 0.253);
}
.profile-pic-setting {
  border: 2px solid #ff1c1454;
  border-radius: 50%;
  margin: 3px;
  cursor: pointer;
  box-shadow: rgba(197, 62, 52, 0.452) 0px 2px 20px;
  transition: all 1s;
}
.profile-pic-setting:hover {
  margin-top: 10px;
  transform: scale(1.5);
  transition: all 1s;
}
.profile-pic-setting-active {
  border: 2px solid #37ff1454;
  border-radius: 50%;
  margin: 3px;
  cursor: context-menu;
  box-shadow: rgba(52, 197, 52, 0.452) 0px 2px 20px;
  transition: all 1s;
}
.profile-pic-setting-active:hover {
  margin-top: 10px;
  transform: scale(1.5);
  transition: all 1s;
}
.new-line {
  white-space: pre-line;
}
.msg-link {
  color: white;
  display: inline-flex;
  padding: 6px;
  border-radius: 10px;
}
.msg-link:hover {
  transform: scale(1.1);
}
.history {
  height: 100px !important;
  border: 2px solid rgba(255, 255, 255, 0.329);
  overflow-y: auto !important;
  border-radius: 15px;
}
.history-item {
  border-bottom: 1px solid rgba(255, 255, 255, 0.329);
}
.history-item-child {
  border-right: 1px solid rgba(255, 255, 255, 0.329);
}
.lock-quest {
  filter: blur(6px);
}
.none-blur {
  filter: blur(0px) !important;
}
.start-120 {
  left: 120% !important;
}

.bg-purpule {
  background-color: #710b99 !important;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.right-border {
  border-right: 1px solid rgba(255, 255, 255, 0.377);
}
.max-h-400 {
  max-height: 400px !important;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.answer-image {
  max-width: 100% !important;
  max-height: 150px !important;
  padding: 3px;
  border: 2px dotted rgba(255, 255, 255, 0.342);
}
.user-image {
  max-width: 80% !important;
}
.h-100vh {
  height: 100vh;
}
.notification {
  max-width: 600px !important;
  min-width: 200px;
  min-height: 104px;
  border: 2px solid rgba(255, 255, 255, 0.144);
  padding: 5px;
  border-radius: 10px;
}
.reward-notif {
  border: 1px solid rgba(255, 255, 255, 0.267);
  border-radius: 10px;
  padding: 5px;
}
.pb-36 {
  padding-bottom: 36px;
}
.accordion-button {
  max-height: 10px !important;
  font-size: 0.7rem !important;
  color: #0dcaf0 !important;
  border: none !important;
}
.accordion-button:not(.collapsed) {
  /* display: none !important; */
  background-image: none !important ;
  content: "︿";
  /* content: "▽"; */
  border: none !important;
}
.accordion-button::after {
  background-image: none !important ;

  content: "﹀";
  /* content: "△"; */
  border: none !important;
}
.accordion-button:focus {
  -webkit-box-shadow: 0px 0px 0px #39ff14 !important;
  box-shadow: 0px 0px 0px #39ff14 !important;
  border: none !important;
}
.accordion-item {
  background-color: transparent !important;
}
iframe {
  display: none !important;
}
.switch-handle {
  background-color: #6c6464 !important;
}
.z-1000 {
  z-index: 1000;
}
.key-log {
  height: 500px !important;
  overflow: auto !important;
}
